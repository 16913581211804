'use strict';

import Fuse from 'fuse.js';
import {Bootstrap} from './lib/utilities.js';

const data = Bootstrap.load('bootstrap-employees');
const employees = (data ? data : []);

const searchField = document.querySelector('#search');
const resultsEl = document.querySelector('#results');

if (searchField) {
  searchField.addEventListener('input', updateResults);
}

const fuse = new Fuse(employees, {
  keys: [{
    name: 'description',
    weight: 1,
  }, {
    name: 'formattedName',
    weight: 2,
  },
  {
    name: 'firstName',
    weight: 3,
  },
  {
    name: 'middleName',
    weight: 1,
  },
  {
    name: 'lastName',
    weight: 3,
  },
  {
    name: 'jobTitle',
    weight: 3,
  },
  {
    name: 'group',
    weight: 2,
  },
  {
    name: 'country',
    weight: 3,
  }],
  minMatchCharLength: 2,
  ignoreLocation: true,
  threshold: '0.3',
});


/**
 * Updates the search results
 * @param {SearchEvent} e
 * @listens SearchEvent
 */
function updateResults(e) {
  const results = fuse.search(e.target.value);
  let resultsString = '';

  results.forEach((result) => {
    let metaString = '';
    if (result.item.jobTitle || result.item.group) {
      metaString = `<p class="search__result-meta type-body">${result.item.jobTitle ? result.item.jobTitle : ''}${result.item.jobTitle && result.item.group ? ' — ' : ''}${result.item.group ? result.item.group : ''}</p>`;
    }

    // ${result.item.country && result.item.country != 'USA' && result.item.country != 'United States' ? '<p class="search__result-loc type-ui">' + result.item.country + '</p>' : ''}

    resultsString += `<a href="/${result.item.id}/" class="search__result">
      <h2 class="search__result-title type-headline">${result.item.formattedName}</h2>
      ${metaString}
    </a>`;
  });

  resultsEl.innerHTML = resultsString;
}
